export const httpGet = (url) => {
	return new Promise((resolve, reject) => {
		fetch(url)
			.then((res) => {
				if (!res.ok) {
					throw new Error(res.statusText);
				}
				return res.json();
			})
			.then((body) => {
				resolve(body);
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

export const httpPost = (url, body) => {
	return new Promise((resolve, reject) => {
		fetch(url, {
			method: "POST",
			body: JSON.stringify(body),
		})
			.then((res) => {
				if (!res.ok) {
					throw new Error(res.statusText);
				}
				return res.json();
			})
			.then((body) => {
				resolve(body);
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

export const httpDelete = (url, body) => {
	return new Promise((resolve, reject) => {
		fetch(url, {
			method: "DELETE",
			body: body ? JSON.stringify(body) : null,
		})
			.then((res) => {
				if (!res.ok) {
					throw new Error(res.statusText);
				}
				return res.json();
			})
			.then((body) => {
				resolve(body);
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

export const httpPostFormData = (url, formData) => {
	return new Promise((resolve, reject) => {
		fetch(url, {
			method: "POST",
			body: formData,
		})
			.then((res) => {
				if (!res.ok) {
					throw new Error(res.statusText);
				}
				return res.json();
			})
			.then((body) => {
				resolve(body);
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

export const uploadFileToS3 = (formData) => {
	return httpPostFormData("/api/file/upload", formData);
};