import * as React from "react";
import { Route, Routes } from "react-router-dom";


import Home from "./pages/Home";


const App = () => {

	// const [project, setProject] = React.useState("Supplier Data Validation");

	// const handleChange = (value) => {
	// 	// console.log("handlechange", value);
	// 	setProject(value.target.value);
	// };


	return (
		<>

			{/* <Drawer
				anchor="left"
				open={state["left"]}
				onClose={toggleDrawer("left", false)}
			>
				{list("left")}
			</Drawer> */}
			<Routes>
				<Route path="/" element={<Home />} />
			</Routes>
		</>
	);
};

export default App;
