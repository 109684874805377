import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { uploadFileToS3 } from "../utils/http";

const Home = () => {
	const [file, setFile] = useState(null);
	const [isLoading, setIsLoading] = useState(false); // New loading state

	const onDrop = useCallback((acceptedFiles) => {
		const uploadedFile = acceptedFiles[0];
		setFile(uploadedFile);
	}, []);

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true); // Start loading
		let formData = new FormData();
		formData.append("file", file);
		const result = await uploadFileToS3(formData);
		console.log("result", result);
		setIsLoading(false); // End loading
	};

	return (
		<main className="container">
			<Box sx={{ flexGrow: 1, textAlign: "center", padding: "20px" }}>
				<Box {...getRootProps()} sx={{ border: "2px dashed grey", padding: "20px", marginBottom: "20px", cursor: "pointer" }}>
					<input {...getInputProps()} />
					{file ? (
						<>
							<Typography sx={{ wordBreak: "break-all" }}>{file.name}</Typography>
							<CloudUploadIcon sx={{ fontSize: 60, marginTop: "10px" }} />
						</>
					) : (
						<>
							<Typography>Drag-n-drop some files here, or click to select files</Typography>
							<CloudUploadIcon sx={{ fontSize: 60, marginTop: "10px" }} />
						</>
					)}
				</Box>
				<Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>
					{isLoading ? <CircularProgress size={24} /> : "Submit"}
				</Button>
			</Box>
		</main>
	);
};

export default Home;
