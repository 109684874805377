import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import App from "./App";
import "./index.css";

const theme = createTheme({
	palette: {
		primary: {
			main: "#4530c8",
		},
	},
});

const root = createRoot(document.getElementById("root"));

root.render((
	<BrowserRouter>
		<ThemeProvider theme={theme}>
			<App />

		</ThemeProvider>
	</BrowserRouter>
));
